import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5836483e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-1 gap-4 lts-container scrollable-content" }
const _hoisted_2 = { class: "w-620 text-left form-wrapper" }
const _hoisted_3 = { class: "text-black font-bold text-lg mb-6" }
const _hoisted_4 = { class: "flex justify-between items-center rounded-lg shadow p-4 gap-2" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  key: 0,
  class: "mt-6 flex flex-col gap-4 px-1 py-2 overflow-y-scroll order-list"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "font-bold text-center pt-20" }
const _hoisted_9 = { class: "w-full rounded map-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_next_select = _resolveComponent("vue-next-select")!
  const _component_Datepicker = _resolveComponent("Datepicker")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_OrderCard = _resolveComponent("OrderCard")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_LTSMap = _resolveComponent("LTSMap")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('lts.playback')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_vue_next_select, {
          searchable: "",
          options: _ctx.driverOptions,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSearch($event))),
          onSelected: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSelect($event))),
          "label-by": "name",
          "value-by": "id",
          "close-on-select": "",
          "clear-on-select": ""
        }, null, 8, ["options"]),
        _createVNode(_component_vue_next_select, {
          searchable: "",
          options: _ctx.orderOptions,
          onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleSearchOrder($event))),
          onSelected: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onSelectOrder($event))),
          "label-by": "name",
          "value-by": "id",
          "close-on-select": "",
          "clear-on-select": ""
        }, null, 8, ["options"]),
        _createElementVNode("div", {
          class: _normalizeClass(["absolute top-20 z-20", !_ctx.showCalendar && 'hidden'])
        }, [
          _createVNode(_component_Datepicker, {
            "onUpdate:modelValue": [
              _cache[4] || (_cache[4] = ($event: any) => (_ctx.dateChange($event))),
              _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dateRange) = $event))
            ],
            inline: "",
            modelValue: _ctx.dateRange,
            format: 'dd.MM.yyyy HH:mm - dd.MM.yyyy HH:mm',
            previewFormat: 'dd.MM.yyyy HH:mm - dd.MM.yyyy HH:mm',
            range: "",
            multiCalendars: "",
            class: "w-full"
          }, null, 8, ["modelValue", "format", "previewFormat"])
        ], 2),
        _createElementVNode("button", {
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showCalendar = true)),
          class: "border border-menuColor w-10 flex-shrink-0 h-10 text-menuColor cursor-pointer rounded-full"
        }, [
          _createVNode(_component_BaseIcon, { name: "time" })
        ]),
        _createElementVNode("button", {
          onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.getLocationData && _ctx.getLocationData(...args))),
          disabled: !_ctx.dateRange || _ctx.dateRange.length < 2 || !_ctx.driver,
          class: "disabled:cursor-not-allowed cursor-pointer border border-menuColor w-10 flex-shrink-0 h-10 text-menuColor rounded-full"
        }, [
          _createVNode(_component_BaseIcon, { name: "find" })
        ], 8, _hoisted_5)
      ]),
      (_ctx.ordersData?.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ordersData, (item) => {
              return (_openBlock(), _createBlock(_component_OrderCard, {
                key: item.orderId,
                order: item
              }, null, 8, ["order"]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('lts.no_orders')), 1)
          ])),
      (_ctx.pagination && _ctx.pagination.totalItems)
        ? (_openBlock(), _createBlock(_component_Pagination, {
            key: 2,
            current: _ctx.pageNumber,
            total: _ctx.pagination.totalItems,
            "page-range": 2,
            basicPagination: true,
            "per-page": _ctx.pagination.pageSize,
            onPageChanged: _cache[8] || (_cache[8] = ($event: any) => (_ctx.pageNumber = $event)),
            "text-before-input": _ctx.$t('lts.goto_page')
          }, null, 8, ["current", "total", "per-page", "text-before-input"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_LTSMap, {
        locations: _ctx.locations,
        stopPoints: _ctx.stopPoints,
        summary: _ctx.summary,
        orders: _ctx.ordersData,
        class: "w-100",
        style: {"height":"77%"}
      }, null, 8, ["locations", "stopPoints", "summary", "orders"])
    ])
  ]))
}