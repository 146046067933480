
import { defineAsyncComponent, defineComponent } from 'vue'
import VueNextSelect from 'vue-next-select'
import BaseIcon from '@/shared/components/base-icon/BaseIcon.vue'
import { Driver } from '@/shared/interfaces/driver.interface'
import { DriverActions } from '@/store/modules/drivers/actions'
import { OrderActions } from '@/store/modules/orders/actions'
import { PaginationInterface } from '@/shared/interfaces/pagination.interface'
import { Order } from '@/shared/interfaces/order.interface'
import { useToast } from 'vue-toastification'

export default defineComponent<any>({
  name: 'TripPlayback',
  components: {
    // DriverCard: defineAsyncComponent(() => import('@/views/account/lts-dashboard/components/DriverCard.vue')),
    OrderCard: defineAsyncComponent(() => import('@/views/account/lts-dashboard/components/OrderCard.vue')),
    Pagination: defineAsyncComponent(() => import('@/shared/components/Pagination.vue')),
    LTSMap: defineAsyncComponent(() => import('@/views/account/lts-dashboard/components/LTSMap.vue')),
    VueNextSelect,
    BaseIcon
  },
  data (): any {
    return {
      driver: null as any,
      order: null as any,
      driverOptions: [] as any[],
      orderOptions: [] as any[],
      pageNumber: 1,
      orderItem: {
        estimated_trip_duration: 0,
        is_to_deliver_first: true,
        trip_details_to_darkstore_inward: null,
        destination_longitude: 44.31901931762696,
        id: 4169,
        driver_id: 122,
        user_address: 'address-Personal',
        destination_latitude: 33.33483091095936,
        order_status_id: 5,
        vehicle_type: {
          value: 'Motor',
          id: 2,
          name: 'motor'
        },
        total_delayed_time: null,
        trip_details_from_darkstore_outward: {
          end_address: '88M9+VP3, Baghdad, Iraq',
          end_location: {
            lat: 33.3350218,
            lng: 44.3190055
          },
          start_address: '88M9+VP3, Baghdad, Iraq',
          start_location: {
            lat: 33.3350218,
            lng: 44.3190055
          },
          travel_distance: 0,
          travel_duration: 0,
          waypoints_order: [
            0,
            1
          ],
          overview_polyline: {
            points: '{vmjEy`omG'
          },
          entire_polyline_route: 'yeljE{bwmG`A~@S^}@rAiAxB_@lAUfAk@xAaBxDs@nBoBlFmAfDm@vAq@`BhB@`@DPFFDXTj@R^jACbDSfQIjKOxMOnLKpLEdFKrEEbA_@hB{@vDDhNEbJMfLS@EjAE|E?dME|EaLQcGEkACc@d@RnDcGf@@h@F`CFdBNxEDlBN|EDhB',
          total_travel_duration: 0
        },
        order_id: 'SO-23-BJ-QMQF-00425-3434',
        created_at: '2023-10-24T13:46:21',
        customer_code: '1111',
        order_status: {
          value: 'On The Way',
          id: 5,
          name: 'on_the_way'
        },
        order_bin_info: [
          {
            324234: 123
          },
          {
            42: 123
          }
        ],
        priority: false,
        total_delivery_time: null,
        is_manual: null,
        order_status_time: [
          {
            status: 'preparing',
            value: '2023-10-24 13:46:21'
          },
          {
            status: 'ready_for_pick_up',
            value: '2023-10-24 13:46:52'
          },
          {
            status: 'assigned',
            value: '2023-10-24 13:48:19'
          },
          {
            status: 'picked_up',
            value: '2023-10-24 13:49:08'
          },
          {
            status: 'on_the_way',
            value: '2023-10-24 13:52:04'
          },
          {
            status: 'at_the_address',
            value: null
          },
          {
            status: 'canceled',
            value: null
          },
          {
            status: 'returned',
            value: null
          },
          {
            status: 'delivered',
            value: null
          }
        ],
        order_total: '36',
        order_bin_number: '123456',
        zone: {
          polygon: [
            [
              33.3172337746665,
              44.34736885174562
            ],
            [
              33.3185965151513,
              44.33724083050539
            ],
            [
              33.31956476518039,
              44.33797039135744
            ],
            [
              33.320030955802665,
              44.32891525372316
            ],
            [
              33.30618761830319,
              44.32809986218263
            ],
            [
              33.304466013345674,
              44.34243358715822
            ],
            [
              33.304107341368294,
              44.34513725384523
            ]
          ],
          id: 6,
          name: 'al amerat'
        },
        zone_id: 6,
        customer_phone_number: '+4232423423',
        dark_store: {
          darkstore_setting: [
            {
              max_threshold_waiting_time: 60,
              email_address: 'voltagehigh7@gmail.com',
              delivered_orders_distance_weight: 1,
              score_divisor: {
                name: 'multiplication',
                value: 1
              },
              darkstore_id: 10,
              waiting_time_at_the_darkstore_weight: 0.5,
              max_distance_from_dark_store: 2000,
              stop_auto_assignment: false,
              max_orders_assigned: 3,
              interval_time: 600,
              delivered_orders_count_weight: 1,
              interval_weight: 1.5,
              id: 10
            }
          ],
          name: 'DSBGK002-Al-Bejia - TG',
          close_time: '20:32:03',
          latitude: 33.32716369628906,
          longitude: 44.36030960083008,
          id: 10,
          open_time: '20:32:03'
        },
        customer_address_details: {
          address_flat: '',
          address_floor: '',
          address_phone: '',
          address_building: '',
          address_district: '',
          address_nickname: '',
          address_address_line1: '',
          address_neighbourhood: '',
          address_nearest_landmark: ''
        },
        total_early_delivery_time: null,
        driver: {
          driver_info: {
            username: 'aromaniiBE',
            last_name: 'andr',
            user_phone_number: '0745 786 5756',
            first_name: 'alex',
            id: 219
          },
          is_busy: 1,
          id: 122
        }
      },
      dateRange: null as any,
      showCalendar: false
    }
  },
  mounted () {
    this.$store.dispatch(`drivers/${DriverActions.FETCH_DRIVERS_AUTOCOMPLETE}`,
      {
        pageNumber: 1,
        pageSize: 1000,
        search: ''
      }
    )
  },
  methods: {
    getOrders () {
      const params = {
        pageNumber: this.pageNumber ? this.pageNumber : 1,
        pageSize: 1000, // we need to get all the data
        reset: true,
        driverId: this.driver ? this.driver.id : null
      }
      this.$store.dispatch(`orders/${OrderActions.FETCH_ORDERS}`, params)
    },
    handleSearch (event: any): void {
      this.$store.dispatch(`drivers/${DriverActions.FETCH_DRIVERS_AUTOCOMPLETE}`,
        {
          pageNumber: 1,
          pageSize: 1000, // we need to get all the data
          search: event.target.value
        }
      )
    },
    onSelect (data: any): void {
      this.driver = data
      this.getOrdersAutocomplete()
    },
    handleSearchOrder (event: any): void {
      this.getOrdersAutocomplete(event.target.value)
    },
    onSelectOrder (data: any): void {
      this.order = data
      const order: Order = this.ordersData.find((el: Order) => el.orderId === data.id)
      if (!data.dateRangeFrom || !data.dateRangeTo) {
        const toast = useToast()
        toast.warning('Select date range before submitting filter')
      } else {
        const assignedTime = data.dateRangeFrom
        const lastStatusTime = data.dateRangeTo
        // const assignedTime = order.orderStatusTime.find((el: any) => el.status === 'assigned').value
        // const lastStatusName = order.orderStatus.name
        // const lastStatusTime = order.orderStatusTime.find((el: any) => (el.status === 'returned' && el.value) ||
        //   (el.status === 'delivered' && el.value) || (el.status === 'canceled' && el.value) || null
        // )
        // console.log('lastStatusTime', lastStatusTime)

        this.dateRange = [assignedTime && new Date(assignedTime), lastStatusTime && new Date(lastStatusTime)]
      }
    },
    dateChange (e: any) {
      this.showCalendar = false
    },
    getOrdersAutocomplete (query = null): void {
      const params = {
        pageNumber: this.pageNumber ? this.pageNumber : 1,
        pageSize: 1000, // we need to get all the data
        driverId: this.driver?.id,
        search: query
      }
      this.$store.dispatch(`orders/${OrderActions.FETCH_ORDERS_AUTOCOMPLETE}`, params)
    },
    getLocationData () {
      this.getOrders()
      this.$store.dispatch(`drivers/${DriverActions.FETCH_TRIP_LOCATIONS}`,
        {
          driverId: this.driver.id,
          dateRange: this.dateRange
        }
      )
    }
  },
  computed: {
    tripLocations (): any {
      return this.$store.getters['drivers/getTripLocations']
    },
    tripLocationsLoading (): any {
      return this.$store.getters['drivers/getTripLocationsLoading']
    },
    drivers (): Driver[] {
      return this.$store.getters['drivers/getDriversAutocomplete']
    },
    ordersDataAutocomplete () {
      return this.$store.getters['orders/getOrdersAutocomplete']
    },
    ordersData () {
      return this.$store.getters['orders/getOrders']
    },
    pagination (): PaginationInterface {
      return this.$store.getters['orders/getPagination']
    },
    locations () {
      return this.tripLocations?.locations ?? []
    },
    stopPoints () {
      return this.tripLocations?.stops ?? []
    },
    summary () {
      return this.tripLocations?.summary ?? null
    }
  },
  watch: {
    drivers (drivers: any[]) {
      this.driverOptions = drivers.map((driver: any) => {
        return { name: driver.userName, id: driver.driverId }
      })
    },
    ordersDataAutocomplete (orders: any[]) {
      this.orderOptions = orders.map((order: any) => {
        return {
          id: order.orderId,
          name: order.orderId
        }
      })
    },
    pageNumber () {
      this.getOrders()
    }
  }
})
